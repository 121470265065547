var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b bg-white border-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Enter Purchase Code")]),_vm._v(" "),_c('fieldset',{staticClass:"form-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.purchase_code),expression:"purchase.purchase_code"}],staticClass:"form-control",attrs:{"type":"text","name":"purchase_code","placeholder":"Please Enter Purchase Code"},domProps:{"value":(_vm.purchase.purchase_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "purchase_code", $event.target.value)}}})]),_vm._v(" "),(
                                                    _vm.errors.has(
                                                        'purchase_code'
                                                    )
                                                )?_c('small',{staticClass:"form-text text-danger",domProps:{"textContent":_vm._s(
                                                    _vm.errors.get(
                                                        'purchase_code'
                                                    )
                                                )}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-md-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.purchaseCode()}}},[_vm._v("\n                                            Submit\n                                        ")])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"\n                card card-custom\n                gutter-b\n                bg-transparent\n                shadow-none\n                border-0\n              "},[_c('div',{staticClass:"\n                  card-header\n                  align-items-center\n                  border-bottom-dark\n                  px-0\n                "},[_c('div',{staticClass:"card-title mb-0"},[_c('h3',{staticClass:"card-label mb-0 font-weight-bold text-body"},[_vm._v("\n                                            Purchase Code\n                                        ")])])])])])])}]

export { render, staticRenderFns }