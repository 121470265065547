<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-12 col-xl-12">
                <div
                  class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  "
                >
                  <div
                    class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    "
                  >
                    <div class="card-title mb-0">
                      <h3 class="card-label mb-0 font-weight-bold text-body">
                        Inventario
                      </h3>
                    </div>
                    <div class="icons d-flex"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card card-custom gutter-b bg-white border-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Almacen</label>
                          <select class="form-control" 
                                  v-model="warehouse_id"
                            >
                            <option value="">todos</option>
                            <option v-for="warehouse in warehouses" :value="warehouse.warehouse_id">
                              {{warehouse.warehouse_name}}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-3">
                          <label>Categoria</label>

                         <select class="form-control" 
                                v-model="category_id"
                                v-on:change="fetchStockOnHand()"
                          >
                            <option value="">todos</option>
                            <option v-for="category in product_categories" :value="category.id">
                              {{category.slug.replace(/[^\w\s]/gi, ' ')}}
                            </option>
                          </select>
                      </div>

                      <div class="col-md-3">
                        <label>Producto</label>
                        <select class="form-control" 
                                v-model="product_id"
                                v-on:change="fetchStockOnHand()">
                            <option value="">todos</option>
                            <option v-for="product in products" :value="product.product_id">
                              {{product.detail ? product.detail[0].title : "" }}
                            </option>
                          </select>
                      </div>

                      <div class="col-md-3">
                        <button style="margin-top:20px" class="btn btn-success" @click="fetchStockOnHand('')">Filtrar</button>
                        <button style="margin-top:20px" class="btn btn-success" @click="generarReporteDetallado()">Exportar XLS</button>
                      </div>
                    </div>
                    <div>
                      <div class="table-responsive" id="printableTable">
                        <div
                          id="productUnitTable_wrapper"
                          class="dataTables_wrapper no-footer"
                        >
                          <div
                            class="dataTables_length"
                            id="productUnitTable_length"
                          >
                            <label
                              >Mostrar
                              <select
                                name="productUnitTable_length"
                                class=""
                                v-model="limit"
                                v-on:change="fetchStockOnHand()"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                              entradas</label
                            >
                          </div>
                          <table
                            id="productUnitTable"
                            class="display dataTable no-footer"
                            role="grid"
                          >
                            <thead class="text-body">
                              <tr role="row">
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                  style="width: 25px"
                                  @click="sorting('id')"
                                  :class="
                                    (this.$data.sortType == 'asc' ||
                                      this.$data.sortType == 'ASC') &&
                                    this.$data.sortBy == 'id'
                                      ? 'sorting_asc'
                                      : (this.$data.sortType == 'desc' ||
                                          this.$data.sortType == 'DESC') &&
                                        this.$data.sortBy == 'id'
                                      ? 'sorting_desc'
                                      : 'sorting'
                                  "
                                >
                                  SKU
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="stock: activate to sort column ascending"
                                  style="width: 95px"
                                >
                                  Producto
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="stock: activate to sort column ascending"
                                  style="width: 65px"
                                >
                                  Categoria
                                </th>
                                <!-- th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Phone No: activate to sort column ascending" 
                                  style="width: 35px"
                                >
                                  Entradas a Inventario
                                </th>
                                <th
                                 
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 35px"
                                >
                                  Comercializado
                                </th-->

                                <th  
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 35px"
                                >
                                  Existencias <!--En Inventario-->
                                </th>
                                <th  
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 35px"
                                >
                                  Precio
                                </th>
                                <th  
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 35px"
                                >
                                  Costo
                                </th>
                                <!--th
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 35px; text-align:center"                                  
                                >
                                  Valor total del inventario
                                </th-->
                              </tr>
                            </thead>
                            <tbody class="kt-table-tbody text-dark">
                              <tr
                                class="kt-table-row kt-table-row-level-0 odd"
                                role="row"
                                v-for="(stock, i) in stocks"
                                v-bind:key="i"
                              >
                                <td class="sorting_1">
                                  {{ stock.product.sku }}
                                </td>
                                <td>
                                  {{
                                    stock.product  && stock.product.detail ? stock.product.detail[0].title : ""
                                  }}
                                </td>
                                <td>
                                  {{ stock.product.category[0].category.category_slug }}
                                </td>
                                <!--td>
                                  {{ stock.stock_in ? stock.stock_in : 0 }}
                                </td>
                                <td>
                                  {{ stock.stock_out ? stock.stock_out : 0 }}
                                </td-->
                                <td>
                                  {{ stock.remaining ? stock.remaining : 0 }}
                                </td>
                                <td>$ 
                                  {{ stock.product.price }}
                                </td>
                                <td>$ 
                                  {{ stock.CostoTotal / stock.UltimaQty  }}
                                </td>
                                <!--td style="text-align: right;">$  
                                  {{
                                    stock.current_value_simple_product
                                      ? stock.current_value_simple_product
                                          .total_amount
                                      : (stock.current_value_variable_product
                                      ? stock.current_value_variable_product
                                          .total_amount
                                      : 0)
                                  }}
                                </td-->
                              </tr>
                            </tbody>
                          </table>
                          <ul class="pagination pagination-sm m-0 float-right">
                            <li
                              v-bind:class="[
                                { disabled: !pagination.prev_page_url },
                              ]"
                            >
                              <button
                                class="page-link"
                                href="#"
                                @click="
                                  fetchStockOnHand(pagination.prev_page_url)
                                "
                              >
                                Anterior
                              </button>
                            </li>

                            <li class="disabled">
                              <a class="page-link text-dark" href="#"
                                >Page {{ pagination.current_page }} of
                                {{ pagination.last_page }}</a
                              >
                            </li>

                            <li
                              v-bind:class="[
                                { disabled: !pagination.next_page_url },
                              ]"
                              class="page-item"
                            >
                              <button
                                class="page-link"
                                href="#"
                                @click="
                                  fetchStockOnHand(pagination.next_page_url)
                                "
                              >
                                Siguiente
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorHandling from "./../../ErrorHandling";
export default {
  data() {
    return {
      sortBy: "id",
      sortType: "ASC",
      limit: 10,
      error_message: "",
      pagination: {},
      stocks: [],
      warehouses: [],
      products: [],
      warehouse_id:"",
      product_id:"",
      sku:"",
      category_id:"",
      product_categories: [],
      token: [],
      errors: new ErrorHandling(),
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {
    fetchStockOnHand(page_url) {
    this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/reports/stock-on-hand";
      var arr = page_url.split("?");

      if (arr.length > 1) {
        page_url += "&limit=" + this.limit;
      } else {
        page_url += "?limit=" + this.limit;
      }

      page_url += "&sortBy=" + this.sortBy + "&sortType=" + this.sortType;
      page_url += "&warehouse_id=" + this.warehouse_id + "&category_id=" + this.category_id+"&product_id="+this.product_id+"&sku="+this.sku;

      var responseData = {};

      axios
        .get(page_url, this.token)
        .then((res) => {
          this.stocks = res.data.data;
          vm.makePagination(res.data, res.data.links);
        })
        .finally(() => (this.$parent.loading = false));
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: meta.next_page_url,
        prev_page_url: meta.prev_page_url,
      };

      this.pagination = pagination;
    },
    fetchWarehouses(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/warehouse?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.warehouses = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchProductCategories(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/category?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.product_categories = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchProducts(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/product?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    sorting(sortBy) {
      this.sortBy = sortBy;
      this.sortType =
        this.sortType == "asc" || this.sortType == "ASC"
          ? (this.sortType = "desc")
          : (this.sortType = "asc");
      this.fetchStockOnHand("");
    },

    generarReporteDetallado(){
      this.$parent.loading = true;
        var token = localStorage.getItem("token");
        const config = {     
          responseType: 'blob',   
          headers: {
              Authorization: `Bearer ${token}`,
              'X-CSRF-TOKEN': this.csrf,
              responseType: "blob"
          }
        };
      
      var responseData = {
          fecha_inicio: this.fechaReporteInicio,
          fecha_fin: this.fechaReporteFinal,
          "_token": this.csrf
        };

      axios.post("/stock/exportar",responseData,config) 
      .then((res) => {
            const anchorElement = document.createElement('a');
            let blob = new Blob([res.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })

              const url = window.URL.createObjectURL(blob);
              anchorElement.href = url;
              anchorElement.download = "Reporte de inventario";
              anchorElement.click();

              window.URL.revokeObjectURL(url);
              //url = window.URL.createObjectURL(blob);
              //window.open(url);
        })
        .finally(() => (this.$parent.loading = false));
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.fetchProductCategories();
    this.category_id = 1;    
    this.fetchStockOnHand("");
    this.fetchWarehouses();
    this.fetchProducts();
    //this.generarReporteDetallado();
  },
  
};
</script>
